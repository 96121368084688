import { horizFetch, horizFetchPost } from '../core/api'

export const useInvestApi = () => ({
  getCitiesList: async (query = { skip: 0, limit: 20 }) => {
    return await horizFetch('/cities', { query })
  },
  getBestStrategy: async (query) => {
    const strategy = await horizFetch('/bestStrategy', {
      query,
    })

    return strategy.bestStrategy
  },
  getCitiesPerformance: async (body) => {
    return await horizFetchPost('/cityPerformance', {
      body,
    })
  },
  getCity: async (city_) => {
    return await horizFetch('/cityAnalysis', {
      query: {
        city: city_.city,
        postalCode: city_.postalCode,
        revenus: city_.revenus,
      },
    })
  },
})
